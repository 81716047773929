import { React, useState, useRef, useEffect } from "react";
import Checkbox from "../Components/Checkbox";
import Radio from "../Components/Radio";
import SubmitSpinner from "../Components/SubmitSpinner";

export default function Registration({ privacyDialog }) {
  const [giveContribution, setGiveContribution] = useState(false);
  const [socialActivities, setSocialActivities] = useState(false);
  const [childCare, setChildCare] = useState(false);
  const [GDPR, setGDPR] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");

  const handlePrivacy = (e) => {
    privacyDialog.current.showModal();
  };

  const formRef = useRef();

  const [registrationActive, setRegistrationActive] = useState(false);

  useEffect(() => {
    fetch("https://iberiangw2024.usal.es/api/get_registration_open.php",
    {
      mode: "cors",
    })
        .then((res) => res.json())
        .then((data) => {
          setRegistrationActive(data.registrationOpen)
        })
  }, [])

  function handleSubmit(e) {
    e.preventDefault();

    if (!registrationActive) {
      return null
    }

    const regFormData = new FormData(formRef.current);

    if (!regFormData.get("contribution")) {
      regFormData.append("contribution", "false");
      regFormData.append("contrib_type", "null");
      regFormData.append("contrib_title", "null");
      regFormData.append("contrib_abstract", "null");
    } 

    if (!regFormData.get("social_activities")) {
      regFormData.append("social_activities", "false");
    }

    if (!regFormData.get("child_care")) {
      regFormData.append("child_care", "false");
    }

    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
      fetch("https://iberiangw2024.usal.es/api/register.php", {
        method: "POST",
        body: regFormData,
        mode: "cors",
      })
        .then((res) => {
          fetch(
            "https://iberiangw2024.usal.es/api/send_confirmation_email.php",
            {
              method: "POST",
              body: regFormData,
              mode: "cors",
            }
          )
            .then((res) => {
              setSubmitted(true);
              setRegistrationActive(false);
              setSubmitStatus("success");
            })
            .catch((error) => {
              setSubmitted(true);
              setSubmitStatus("error");
            });
        })
        .catch((error) => {
          setSubmitted(true);
          setSubmitStatus("error");
        });
    }, 3000);
  }

  return (
    <div
      className="section"
      style={{ color: "#dcdcdc", backgroundColor: "#2e3c56" }}
    >
      <span
        id="registration"
        style={{ position: "absolute", top: "-90px" }}
      ></span>
      <div className="section-title">Registration, fee and abstract submission</div>
      <div className="section-content-flex">
        <div className="subsection">
          <div className="registration-deadlines">
            <a
              className="deadline"
              href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Registration+Deadline+IberianGW2024&details=Registration+and+abstract+submission+deadline+for+the+IberianGW2024+conference+in+Salamanca&location=Salamanca&dates=20240401T082300Z/20240401T082300Z"
            >
              <div className="title">Registration and call for abstracts</div>
              <div className="date">March 4th to May 29th</div>
            </a>
          </div>
	 <div className="registration-deadlines">
            <a
              className="deadline"
              href=""
            >
              <div className="title">Preliminary Program</div>
              <div className="date">June 13rd</div>
            </a>
          </div> 
	<div className="registration-deadlines">
            <a
              className="deadline"
              href=""
            >
              <div className="title">Fee payment deadline</div>
              <div className="date">June 13th</div>
            </a>
          </div>
        </div>
        <div className="subsection">
          {!registrationActive && <div className="closed">Registration is now closed</div>}
          <div className="registration-form">
            <form action="" ref={formRef} onSubmit={handleSubmit}>
              <fieldset disabled={!registrationActive}>
                <div className="form-group">
                  <label htmlFor="name">Name and surname</label>
                  <input name="name" type="text" required />
                </div>
                <div className="form-group">
                  <label htmlFor="affiliation">Affiliation</label>
                  <input name="affiliation" type="text" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input name="email" type="email" required />
                </div>
              </fieldset>
              <fieldset disabled={!registrationActive}>
                <div className="form-group">
                  <Checkbox
                    htmlId="contrib"
                    checked={giveContribution}
                    setChecked={setGiveContribution}
                    label="I would like to give a contribution:"
                    htmlName="contribution"
                  />
                </div>
                {giveContribution ? (
                   <div className="optional">
		   <div className="form-group">
        <label htmlFor="contrib_type">Select the type of contribution: </label>
		    <Radio/>
		   </div> 
                    <div className="form-group">
                       <label htmlFor="contrib_title">Title</label>
                       <input name="contrib_title" type="text" required></input>
                    </div>
		   
                   <div className="form-group">
                       <label htmlFor="contrib_abstract">Abstract</label>
                       <textarea
                        name="contrib_abstract"
                        type="text"
                        rows="5"
                        required
                       ></textarea>
                    </div>
		   </div>
		 
                ) : null}
	        
                <div className="form-group">
                  <Checkbox
                    htmlId="socact"
                    checked={socialActivities}
                    setChecked={setSocialActivities}
                    label="I would like to participate in social activities:"
                    htmlName="social_activities"
                  />
                </div>
                <div className="form-group">
                  <Checkbox
                    htmlId="childcare"
                    checked={childCare}
                    setChecked={setChildCare}
                    label="I am interested in child care support during the sessions:"
                    htmlName="child_care"
                  />
                </div>
                <div className="form-group">
                  <Checkbox
                    htmlId="gdpr"
                    checked={GDPR}
                    setChecked={setGDPR}
                    label={
                      <p>
                        I have read and understood the{" "}
                        <span className="inline-link" onClick={handlePrivacy}>
                          privacy policy
                        </span>{" "}
                      </p>
                    }
                    htmlName="privacy_policy"
                    required={true}
                  />
                </div>
              </fieldset>
              <fieldset disabled={!registrationActive | !GDPR}>
                <button className="submit-button">
                  {" "}
                  {submitting ? "Submitting..." : "Submit"}
                </button>
              </fieldset>
            </form>
            {submitting ? (
              <div className="optional">
                <div className="submitting-bar"></div>
              </div>
            ) : null}
            {submitted ? (
              <div className="optional">
                <div className={"submitted " + submitStatus}>
                  Your registration has been correctly submitted. Check your
                  email.
                </div>
              </div>
            ) : null}
          </div>
          <p
            style={{
              fontWeight: 200,
              fontSize: "0.9rem",
              alpha: 0.5,
              fontStyle: "italic",
            }}
          >
            *Upon registering to the conference you should receive a
            confirmation email. If you don't receive one within 24 hours, please
            get in contact with{" "}
            <a className="inline-link" href="mailto:iberiangw2024@usal.es">
              iberiangw2024@usal.es
            </a>
            .
          </p>
        </div>
      </div>
      <div className="fee">
            <p>The <b>registration fee</b> is:</p>
            <ul>
              <li>60€ for PhD students;</li>
              <li>120€ for postdocs and permanent researchers.</li>
            </ul>
            <p>To pay the registration fee
               <a
                 href="https://gestion.fundacionusal.es/Matricula/entrance/curso:2024s034cd0124045/lang:eng"
                 className="inline-link"
	  	 style={{color:'#2e8ad1'}}
	  	 target='_blank'
               >
               , please access this link
               </a>{" "}

	        and fill up the form with your information.</p>
	    
            
      </div>
    </div>
  );
}
