import React from "react";

const invitedSpeakers = [
  {
    'name': 'Richard',
    'surname': 'Brito',
    'affiliation': 'Instituto Superior Técnico, Lisboa, Portugal',
    'talk': 'Black holes and gravitational waves as unique probes for ultralight bosons',
  },
  {
    'name': 'José A.',
    'surname': 'Font',
    'affiliation': 'Universitat de València, Spain',
    'talk': 'Gravitational waves from binary neutron star mergers: thermal effects, post-merger spectra, and non-convex dynamics',
  },
  {
    'name': 'David',
    'surname': 'Keitel',
    'affiliation': 'Departament de Física, Universitat de les Illes Balears, Spain',
    'talk': 'LIGO-Virgo-KAGRA results so far and status of the O4 run',
  },
  {
    'name': 'Carlos F.',
    'surname': 'Sopuerta',
    'affiliation': 'IEEC-CSIC, Institut d´Estudis Espacials de Catalunya, Spain',
    'talk': 'Challenges and opportunities to survey the Universe in the low-frequency band',
  },
  {
    'name': 'Mario',
    'surname': 'Martinez',
    'affiliation': 'IFAE, Spain',
    'talk': 'The Einstein Telescope: a 3rd generation GW observatory in Europe',
  },
  {
    'name': 'Nils',
    'surname': 'Andersson',
    'affiliation': 'University of Southampton, United Kindgom',
    'talk': 'A Gravitational-Wave Perspective on Neutron-Star Seismology',
  },
  {
    'name': 'Anna',
    'surname': 'Heffernan',
    'affiliation': 'Departament de Física, Universitat de les Illes Balears, Spain',
    'talk':'Waveforms for LISA'
  }, 
  {
    'name': 'Eva',
    'surname': 'Lope Oter',
    'affiliation': 'Universidad Complutense de Madrid, Spain',
    'talk': 'Neutron Star Equations of State and GW'
  },
]

const sortBySurname = (a, b) => {
  if (a.surname.toUpperCase() > b.surname.toUpperCase()) {
    return 1;
  } else {
    return -1;
  }
};

export default function Info() {
  return (
    <div
      className="section"
      style={{ backgroundColor: "#eeeeee", color: "#ededed" }}
    >
      <span id="info" style={{ position: "absolute", top: "-90px" }}></span>
      <div className="section-content-flex" style={{ gap: "1rem" }}>
        <div className="subsection">
          <div className="text-section">
            <p>
              The Iberian Gravitational Waves Meeting is an international conference organised yearly since 2011 by different members of the Red Temática de Ondas Gravitacionales (REDONGRA). This year it will be hosted at the University of Salamanca, Spain.
            </p>
            <p>
              The idea is to bring together researchers with interest in the development of Gravitational Waves Astronomy, including researchers from related areas of Astrophysics, multi-messenger Astronomy, Cosmology, Fundamental Physics, Instrumentation and Data analysis and, importantly, to foster collaboration among them. 
            </p>
            <p>
              The  meeting will be held from June 24th to June 26th 2024 at the {" "}
	    <a
	       href="https://maps.app.goo.gl/aVGZNkEUMg2z1R1y6"
	       className = "inline-link"
	    >
	     Facultad de Traducción building.
	    </a>{" "}
            </p>
            <p>
              We look forward to seeing  you in Salamanca.
            </p>
     </div>
              
        </div>
	</div>  


        <div className="subsection">
          <div className="section-title" style = {{color: 'black'}}>
            Invited Speakers
          </div>
          <div className="invited-speakers">
            {invitedSpeakers.sort(sortBySurname).map((speaker) => {
              return(
                <div className="invited-speaker">
                  <div className="invited-speaker-name">
                    {speaker.name} {speaker.surname}
                  </div>
                  <div className="invited-speaker-affiliation">
                    {speaker.affiliation}
                  </div>
                  <div className="invited-speaker-talk">
                    {speaker.talk}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="section-title" style = {{color: 'black'}}>
            Sponsors
          </div>
          <div className="sponsors-logos">
            <div className="sponsor-logo">
              <img src="images/sponsors/ministerio_logo.png" alt="" />
            </div>
            <div className="sponsor-logo" style = {{padding: '2rem'}} id = 'iuffym_logo'>
            <img src="images/sponsors/iuffym_logo.png" alt="" />
            </div>
            <div className="sponsor-logo">
            <img src="images/sponsors/usal_logo.png" alt="" />
            </div>
          
	    <div className="sponsor-logo">
	      <img src="images/sponsors/multidark.jpg" alt="" />
	    </div>
	  </div>
          <div className="clpu-section">
            <div className="section-title" style = {{color: 'black', gridColumn: "span 2"}}>
              Visit to CLPU
            </div>
            <div style={{color:'black'}}>
              After the afternoon session June 24th there is the possibility of visiting the {" "} 
              <a href = "https://www.clpu.es/en/"
              className = "inline-link"
              style = {{color:'#081f45'}}>
              <u>Centro de Láseres Pulsados (CLPU).</u>
              </a>{" "}
              A bus departing from Salamanca will be arranged. For more information and to reserve this visit email ycortes@clpu.es.
              </div>
              <div className="clpu-image">
              <img src="images/clpu.jpg" alt="" />
            </div>
            </div>
            
          </div>    
  );
}
