import React from "react";

const socList = [
 {
    name: "Mar",
    surname: "Bastero Gil",
    affiliation: "Departamento de Física Teórica y del Cosmos, Universidad de Granada, Spain",
  },
  {
    name: "Pablo",
    surname: "Cerdá-Durán",
    affiliation: "Departamento de Astronomía y Astrofísica, Universitat de València, Spain",
  },
  {
    name: "Thomas",
    surname: "Dent",
    affiliation: "Departamento de Física de Partículas, Universidade de Santiago de Compostela, Spain",
  },
  {
    name: "Isabel",
    surname: "Cordero-Carrión",
    affiliation: "Departamento de Matemáticas, Universitat de València, Spain",
  },
  {
    name: "Alicia",
    surname: "Sintes",
    affiliation: "Departamento de Física, Universitat de les Illes Balears, Spain",
  },
  {
    name: "Antonio",
    surname: "Onofre",
    affiliation: "Departamento de Física, Universidade do Minho (CFUMUP, LIP), Portugal",
  },
  {
    name: "Ángeles",
    surname: "Pérez-García",
    affiliation: "Departamento de Física Fundamental, Universidad de Salamanca, Spain",
  },
  {
   name: "Juan",
   surname: "García-Bellido",
   affiliation: "Departamento de Física Teórica, Universidad Autónoma de Madrid, Spain",
  },
];

const sortBySurname = (a, b) => {
  if (a.surname.toUpperCase() > b.surname.toUpperCase()) {
    return 1;
  } else {
    return -1;
  }
};

export default function LOC() {
  return (
    <div
      className="section"
      style={{ color: "white", backgroundColor: "#4b73a8" }}
    >
      <span id="SOC" style={{ position: "absolute", top: "-90px" }}></span>
      <div className="section-title">Scientific Organizing Committee (SOC)</div>
      <div className="section-content-grid">
        {socList.sort(sortBySurname).map((loc, i) => {
          return (
            <div className="soc-item" key={loc.surname}>
              <p>
                <span className="loc-name">{loc.name}</span>{" "}
                <span className="loc-surname">{loc.surname}</span>
              </p>
              <p>
                <div className="soc-affiliation">{loc.affiliation}</div>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
