import { React, useEffect, useState, useRef } from "react";

export default function Group() {
  const [pageScroll, setPageScroll] = useState(0);

  const handleScroll = () => {
    setPageScroll(50 + window.scrollY / 50);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="section">
      <div className="section-title">Group photo</div>
      <img
        src="images/group_photo.jpg"
        alt="USAL"
        style={{ objectPosition: "0px " + pageScroll + "%" }}
      />
    </div>
  );
}
