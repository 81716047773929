import { React, useRef } from "react";

export default function Footer({ privacyDialog }) {
  function handlePrivacy(e) {
    privacyDialog.current.showModal();
  }

  return (
    <div className="footer">
      <div className="footer-img-grid">
        <div className="img">
          <img src="images/usal_logo_badge.png" alt="" />
	      </div>
        </div>
        <div className="footer-link-list">
          <a href="www.usal.es">Universidad de Salamanca</a>
          <a onClick={handlePrivacy}>Privacy policy</a>
        </div>
        <div className="aknowledgment">
          Created by Riccardo Della Monica and David Barba-González © 2024
        </div>
        <dialog className="privacy-policy" ref={privacyDialog}>
          <form method="dialog">
          <p>This Privacy Policy outlines the practices and procedures for the collection, use, and protection of user data on the IberianGW2024 conference website. Please take a moment to review 
	  this Privacy Policy to understand how we handle your data.</p>
	  <p>The personal data requested will consist only of the strictly necessary information to identify and address the request made by the data subject at the time of registration for the 
	  'Iberian GW 2024' conference. Personal data will be collected for the explicit and legitimate purposes mentioned below, and will not be further processed in a manner incompatible with 
	  those purposes.</p>
	  <p>The data subject agrees that their name, last name, and affiliation will be published on the 'Iberian GW 2024' conference website and accepts that the data will be processed, in each 
	  case, for the following purposes:</p>
	  <ul>
	    <li>Managing presentations, events, or conferences promoted by the organization;</li>
	    <li>Sending information about activities and events promoted by the organization;</li>
	    <li>Managing attendance certificates related to the event;</li>
	    <li>Addressing requests for inquiries, complaints, suggestions, or any other requests submitted through the corresponding section on the website.</li>
	    <li>The information collected from the data subject will be retained for as long as necessary to fulfill the purpose for which the personal data was collected, so once the purpose is 
	        achieved, the data will be deleted.</li>
          </ul> 
	  <p>For any question concerning the treatment of the data please get in touch with us at iberiangw2024@usal.es.</p>
          <button>Close</button>
        </form>
      </dialog>
    </div>
  );
}
