import { React, useState, useEffect } from 'react'
import moment from 'moment'



export default function Program() {

  return (
    <div className="section" style = {{backgroundColor: '#c3c3c3', color: '#2e2e2e'}}>
        <span id="program" style = {{position: 'absolute', top: '-90px'}}></span>
        <div className="section-title">
            Program
        </div>
        
        Please see the Program and Book of Abstracts in the links below:
        
        <p>
        {" "}
	      <a
	       href="https://iberiangw2024.usal.es/api/program.pdf"
	       className = "inline-link"
         style = {{color:'#081f45'}}
	      >
        <u>Program.pdf</u>
        </a>{" "}
        </p>
        <p>
	      <a
	       href="https://iberiangw2024.usal.es/api/abstracts.pdf"
	       className = "inline-link"
         style = {{color:'#081f45'}}
	      >
        <u>Abstracts.pdf</u>
        </a>{" "}
        </p>
    </div>
  )
}
